import schttp from 'public/src/services/schttp'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export const fetchCardBindPreRouting = async data => {
  const res = await schttp({
    method: 'POST',
    url: `/pay/payPre/cardBindPreRouting`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return {
    ...res,
    info: {
      ...res?.info,
      countryCode: data.countryCode,
    }
  }
}

export const deletePaymentToken = async data => {
  return await schttp({
    method: 'POST',
    url: `/pay/del_payment_sign_up`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
}

export const deletePaymentSignUp = async data => {
  return await schttp({
    method: 'POST',
    url: `/pay/del_worldpay_token`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
}

export const saveDefaultPayment = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/pay/confirmAutoBindCard',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const queryAutoBindCardInfo = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/pay/queryAutoBindCardInfo',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const fetchPaymentToken = async data => {
  const res = await schttp({
    method: 'GET',
    url: `/pay/get_world_pay_token`,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    params: data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info,
  }
}
