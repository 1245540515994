export const scanCodePayMethods = ['adyen-pix', 'adyen-boleto', 'alipay-pix']

// 跳转卡支付的支付方式集合
export const INDEPENDENT_PCI_METHODS = [
  'worldpay-card',
  'checkout-card3ds',
  'routepay-card',
  'dlocal-card',
  'dlocal-mxcardinstallment',
  'klarna-sofortjs',
  'adyen-card3ds',
  'adyen-card',
  'ebanx-card',
  'ebanx-mxcardinstallment',
  'ebanx-brcardinstallment',
  'ebanx-clcardinstallment',
  'ingenico-card',
  'ebanx-cocardinstallment',
  'dlocal-brcardinstallment',
  'dlocal-clcardinstallment',
  'routepay-cardinstallment'
]

export const NECESSARY_THIRD_SDK = {
  // 'PayPal-GApaypal': [
  //   //Load the client component
  //   'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
  //   //Load the PayPal Checkout component
  //   'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
  //   //手机设备数据
  //   'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
  // ],
  'PayPal-GApaypal': [
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
        id: 'ppga-sdk-client'
      }
    },
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
        id: 'ppga-sdk-checkout'
      }
    },
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js',
        id: 'ppga-sdk-data'
      }
    }
  ],
  // routepay-card
  'routepay-card': [
    {
      label: 'script',
      attrs: {
        src: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.js'
      }
    },
    {
      label: 'link',
      attrs: {
        href: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.css',
        rel: 'stylesheet'
      }
    }
  ],
  'PayPal-card': [
    // Load the client component.
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
        async: true
      }
    },
    // Load the 3D Secure component.
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/three-d-secure.min.js',
        async: true
      }
    },
    // Load the Hosted Fields component.
    {
      label: 'script',
      attrs: {
        src: 'https://js.braintreegateway.com/web/3.85.2/js/hosted-fields.min.js',
        async: true
      }
    },
  ]
}

/**
 * 需调用前置路由的非卡支付路由
 */
export const PRE_ROUTING_PAYMENT_METHODS = [
  'routepay-konbini', 
  'routepay-pix', 
  'routepay-applepay', 
  'routepay-googlepay',
  'routepay-oxxo',
  'routepay-spei',
  'routepay-mercadopago',
  'routepay-yape',
  'routepay-multibanco',
]

/**
 * dlocal相关渠道的支付方式
 */
export const DLOCAL_PAYMENT_METHODS = [
  'dlocal-oxxo',
  'dlocal-boleto',
  'dlocal-pix',
  'dlocal-eft',
  'dlocal-pse',
  'dlocal-nupay',
  'dlocal-spei',
  'dlocal-mercadopago',
  'dlocal-yape'
]

export const MULTIBANCO_PAYMENT_METHODS = ['adyen-multibanco']
